const text = {
  to: "to",
  openCV: "Open resume",
  loadingCV: "Loading resume...",
  errorMessage: "Något gick fel",
  close: "Close",
  availableNow: "Available immediately",
  availableFrom: "Available from ",
  highestEducation: "Highest level of education ",
  wantsMarketSalary: "Wants salary according to market",
  wantSalarySpace: "Salary expectation ",
  krMonth: " Swedish Kronor per month",
  noWantSalary: "Chose not to ask för a salary",
  questionsAnswers: "Questions and answers",
  voiceAnswer: "Voice answer",
  videoAnswer: "Video answer",
  noShowPrint: "Can not be shown in print format",
  recommendedAnswer: "Recommended answer",
  applicantLanguages: "Languages",

  beginner: "Beginner",
  average: "Average",
  advanced: "Advanced",
  nativeLanguage: "Native language",
  competences: "Competences",
  assessmentApplicant: "Your assessment of the applicant",
  notAvailable: "Not available in preview mode",
  grade: "Grade",
  notSpecified: "not specified",
  comment: "Comment",
  mandatoryField: "mandatory field",
  sendingAssessment: "Sending assessment...",
  assessmentSent: "Assessment sent",
  sendAssessment: "Send assessment",
  responsibleRecruiter: "Responsible recruiter",

  noPresentation: "No candidate presentation",
  noCandidateSearchFound: "No candidate matches your search",

  // CandidatePresentationView
  invalidUrl: "The url is invalid",
  fillInCode: "Fill in the access code to show applicant",
  invalidCode: "Invalid access code",
  accessCode: "Access code",
  show: "Show",
  accessThroughCode: "Access through code",
  accessThroughLogin: "Access through logged in user",
  avaibility: 'Availability',
  notice: 'Notice period',
  salary: 'Salary quote',
  price: 'Price / per h',
  aboutCandidate: 'About Candidate',

  // CustomerAnswered
  thankAnswer: "Thank you for your answer!",
  assessmentSentTo:
    "Your assessment has been sent to the responsible recruiter",

  // MatchDocumentForm
  matchDocument: "Please fill in the required information",
  addPicture: "Add profile picture ",
  remove: "Remove",
  addResumePdf: "Add resume (pdf, doxc, png, jpeg)",
  immediately: "Immediately",
  afterDate: "After date",
  selectDate: "Select date",
  selectEducation: "Select level of education",
  levelEducation: "Level of education",
  educationLevel: [
    "High School Graduate",
    "Some college",
    "Associate's degree",
    "Bachelor's degree",
    "Master's degree",
    "Professional degree",
    "Doctorate degree"
  ],
  other: "Other",
  marketSalary: "Market salary",
  specify: "Specify",
  noSpecify: "Do not specify",
  salaryInSEK: "Monthly salary in Swedish Kronor",
  SEK: "SEK",
  applicantLanguagesSpace: "Languages ",
  language: "Language",
  education: "Education",
  experience: "Experience",
  documnet: 'CV/Document',
  offer: 'Offer/document',
  chooseLanguage: "Choose language",
  chooseLevel: "Choose level",
  addLanguage: "Add language",
  competenceQuestions: "Competence questions",
  pleaseGrade:
    "Please grade your competence in the following areas by adjusting the slider",
  questionsWithMultiple: "Questions with multiple ways of answering",
  missingAnswers: "Missing answers on mandatory fields",
  addResume: "Add resume",
  selectDateFrom: "Select the date from which you are available",
  chooseHighestEducation: "Choose your highest level of education",
  wantSalary: "Specify salary",
  languageMissing:
    "A language misses either the language field or the level field",
  addLeastOne: "Add at least one language",
  competenceMissing: "At least one competence question is missing an answer",
  questionMultipleMissing:
    "At least one question with multiple ways of answering is missing an answer",
  documentReady: "The document is ready to be sent in",
  uploadingAnswers: "Uploading answers...",
  sendIn: "Send in answers",

  // CandidateFormAssignment
  of: "of",
  chooseWay: "Choose way of answering",
  text: "Text",
  video: "Video",
  voice: "Voice",
  deleteAnswer: "Delete answer",
  textAnswerTitle: "Text answer",
  writeAnswer: "Write your answer.",
  correctSpelling: "Make sure that your spelling is correct.",
  answerYourself: "Write the answer yourself.",
  videoAnswerTitle: "Video answer",
  unlimitedAnswers: "You get how many attempts you want to record.",
  recommendedLenghtVideo:
    "The recommended length of a video answer is 60 seconds.",
  supportedFormatVideo: ".mp4 is the supported file format.",
  voiceAnswerTitle: "Voice answer",
  recommendedLenghtVoice:
    "The recommended length of a voice answer is 60 seconds.",
  supportedFormatVoice: ".mp3 and .mp4 is the supported file formats.",

  // RecordNowButtonWithPreview
  chosenVideo: "Chosen video",
  uploadFile: "Upload file",
  recordNow: "or record now",
  useCamera: "Use webcam",
  largeVideoFile: "The video file is too large",

  // RecordNowButton
  noSupport: "No browser support",

  // RecordVoiceButtonWithPreview
  useMic: "Use microphone",
  onIphone: "On iPhone?",

  // AlreadyAnswered
  goodJob: "Thank you for answering!",
  youAnswered: "You answered ",
  weWillGetBackToYou:
    "We will get back to you regarding the next step in the recruitment process.",
  youCanClose: "You can now close this page.",

  // ProgressBarPage
  uploading: "Uploading...",
  noClose: "Do not close the browser window.",

  candidates: "Candidates",
  addNote: 'Add',
  notesDescription: 'Your assessment of the candidate: Rate and/or enter a comment.',
  optional: 'optional',
  notVisibleToCandidate: 'not visible to the candidate',
  writeComment: 'Write a comment',
  linkActiveTo: 'Link active to',
  skills: "Competences",
  systemAndIt: "System and IT",



  // Education / Experience
  addEducation: 'Add education',
  school: 'School',
  educationType: 'Exam/Type',
  addExperience: 'Add experience',
  company: 'Company',
  title: 'Title',
  year: 'Year',
  yearFrom: 'From',
  yearTo: 'To',
  info: 'Info',


  previewOfDocument: 'Document preview',
  download: 'Download',

  // Group
  viewCandidates: 'View candidates',
  presentationOfCandidates: 'Presentation of candidates',
}

export default text;